@import 'src/theme/base/functions.scss';
@import 'src/theme/base/variables.scss';

// define grid layout
@mixin grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: rem-calc(30);
    max-width: rem-calc(990);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    //margin: 0 auto;
}

@mixin max-width-layout {
    max-width: rem-calc(990);
    width: 100%;
    margin: 0 auto;
}

@mixin page-container {
    padding-right: rem-calc(17);
    padding-left: rem-calc(17);

    @media screen and (min-width: $bp-desktop) {
        padding-right: rem-calc(30);
        padding-left: rem-calc(30);
    }
}