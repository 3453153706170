$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/v4-shims';
@import '~ngx-bootstrap/datepicker/bs-datepicker.css';

// Required
@import 'variables';
@import 'src/app/app.scss';

@import '~bootstrap-material-design/scss/bootstrap-material-design';

@import '~ngx-toastr/toastr-bs4-alert';
@import '~@ng-select/ng-select/themes/default.theme.css';

@font-face {
    font-family: 'Segoe UI';
    src: url('/assets/fonts/segoeui/segoeui.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe MDL2 Assets';
    src: url('/assets/fonts/segoeui/SegMDL2.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
    font-family: 'Segoe UI', 'Segoe MDL2 Assets';
}

html,
body {
    // height: 100%;
    background-color: #ffffff;
    min-height: 100vh;
    height: fit-content;

    // stile aggiunto per modificare il colore di tutti i link
    a {
        color: $color-ds-blue;
    }

    overflow-x: hidden;
    width: 100%;
}

/**
 * Reset anchor and button decoration
 */
a:hover,
a:focus,
a:active,
button.btn-link:focus,
button.btn-link:active,
button.btn-link:hover {
    text-decoration: none;
}

/**
 * Button styles
 */
button {
    min-width: 140px;
    height: 35px;
    background: $yellow;
    border: 0;

    &.big {
        min-width: 252px;
        height: 50px;
        font-size: 20px;
        line-height: 18px;
    }

    &.disabled {
        background: $gray-100;
        color: $gray-200;
    }

    &.blue {
        background: #336699;
        color: white;
    }

    &.btn-modal {
        font-size: 12px;
        height: 30px;
        text-transform: uppercase;
    }

    .btn:not(.btn-link) {
        text-transform: uppercase;
    }

    &.btn {
        margin-bottom: 0;
        text-transform: capitalize;
    }

    &.btn-icon {
        font-size: smaller;
    }

    &.ladda-button[class*='btn-outline-'],
    &.ladda-button[class*='btn-link'] {
        .ladda-spinner > div > div > div {
            background: $primary !important;
        }
    }

    &.chevron,
    &.minus {
        display: flex;
        align-items: center;
    }

    &.chevron[aria-expanded='true']:before {
        content: '\E96D';
        margin-right: 0.8rem;
    }

    &.chevron[aria-expanded='false']:before {
        content: '\E96E';
        margin-right: 0.8rem;
    }

    &.minus[aria-expanded='true']:before {
        content: '\f068';
        font-size: smaller;
        margin-right: 5px;
    }

    &.minus[aria-expanded='false']:before {
        content: '\f067';
        font-size: smaller;
        margin-right: 5px;
    }

    &.egl-secondary {
        border: 1px solid #ccc;
        display: inline-block;
        cursor: pointer;
        background: white;
        margin-bottom: 0.5rem;
    }
}

.fullscreen-outlet {
    width: 100%;
    margin: 0 auto;
    @include page-container;
}

.order-entry {
    padding-bottom: rem-calc(120);
    @include max-width-layout;
    @include page-container;
}

.order-entry:has(.egl-product-list),
.order-entry:has(.egl-cp-product-list) {
    max-width: 100%;
}

.page-container--no-padding {
    margin-right: rem-calc(-17);
    margin-left: rem-calc(-17);

    @media screen and (min-width: $bp-desktop) {
        margin-right: rem-calc(-30);
        margin-left: rem-calc(-30);
    }
}

/**
 * Main generico del template
 */

main {
    // @include max-width-layout;
    font-size: 14px;
    // min-height: calc(100vh - 44px);
    height: fit-content;
    // padding-top: 44px;
    display: flex;
    flex-direction: column;

    // &.has-dev-bar {
    //     min-height: calc(100vh - 18px);
    //     padding-top: 18px;
    //     padding-bottom: 50px;
    // }

    // &.has-contact-bar {
    //     min-height: calc(100vh - 117px);
    //     padding-top: 145px;
    // }

    // &.has-progress-bar {
    //     min-height: calc(100vh - 184px);
    //     padding-top: 184px;
    //     padding-bottom: 50px;
    // }

    .page-body {
        padding-bottom: 50px;
        padding-top: 50px;
    }
}

.navbar {
    z-index: inherit;
}

.navbar,
.card {
    box-shadow: none;
    border: $card-border-width solid $card-border-color;
}

.thumbnail {
    max-width: 3rem;
    max-height: 3rem;
}

/**
 * Dropdown fixes
 */
.dropdown-toggle::after {
    display: none;
}

.dropdown-menu {
    z-index: 1500;
    right: 0;
    left: auto;
    opacity: 1;
}

// mini-cart related fixes
.list-group-item :first-child {
    margin-right: 05px !important;
}

.list-group-item {
    align-items: inherit !important;
    display: unset;
    flex-flow: initial !important;
    line-height: 1.5;
}

.checkbox label,
.radio label,
label {
    font-size: inherit;
}

.dashed-border {
    border-style: dashed;
}

.table thead th {
    font-size: small;
}

.card .card-header .card-title {
    font-size: $h5-font-size;
}

.note-container,
.attachment-container {
    height: 35vh;
}

.badge {
    text-transform: uppercase;
    padding-top: 0.25rem;
    padding: 0.25rem 0.5rem;
}

app-cart apt-input-field[name='description'] textarea.form-control {
    padding: 0px;
    height: 25px;
}

apt-breadcrumb {
    display: block;
    border-bottom: 1px solid $info;
    background-color: $light;
}

ngx-gallery {
    ngx-gallery-arrows div.ngx-gallery-arrow-wrapper {
        &.ngx-gallery-arrow-right,
        &.ngx-gallery-arrow-left {
            z-index: 100;
        }
    }
}

apt-output-field {
    line-height: $line-height-lg;
}

// hide Request Quote button from cart page for POC
apt-price-summary div[class='card-body'] button.btn.btn-link:not(.text-capitalize):not(.cancel-button) {
    display: none;
}

popover-container.CRPopover {
    width: 400px;
    max-width: 400px;

    &.show {
        animation: showPopover 0.4s ease-in forwards;
    }

    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
}

popover-container.optionPopover {
    width: 600px;
    max-width: 600px;
}

typeahead-container {
    padding-bottom: 0 !important;

    .dropdown-item {
        padding: 0;
        max-width: 100% !important;
    }
}

.card {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
}

.ng-select.picklist {
    .ng-select-container {
        border: 0;
        border-radius: 0;
        border-bottom: $input-border-width solid $input-border-color;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// input,
// select {
//     // font-family: 'Segoe UI';
//     // background-image: none !important;
//     // height: 45px;
//     text-transform: uppercase !important;
// }

div {
    &.main-container {
        width: 100%;
        padding: 1rem 42px;
        align-items: center;
    }

    &.input-group {
        height: 42px;
        margin: 0;
        padding: 0;
        background: #fff;
        border: 1px solid #fff;

        .form-control {
            background-image: none;
        }

        input,
        select {
            border: none;
            border-radius: 0;
            background-color: transparent !important;
            padding-left: 1rem;
            height: 40px;

            &:focus {
                outline: none;
                border-color: inherit;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }

        i {
            font-size: 1.5rem;
            margin: auto;
            padding: 0 0.5rem;

            &.fa {
                color: rgb(92, 92, 92);
                cursor: pointer;
            }

            &.disabled {
                color: #d9d9d9;
            }
        }

        &.error {
            border-color: red;
        }
    }

    &.form-group {
        div {
            &.error {
                color: $red;
                font-size: 11px;
                padding: 0.4rem;
            }
        }

        .form-control {
            background-image: none;
        }
    }

    &.modal-full {
        min-width: 100vw !important;
        min-height: 100vh !important;
        margin: 0;
    }
}

table {
    &.table {
        width: 100%;
        margin: 0;

        thead {
            tr {
                height: 42px !important;
                background-color: rgba(237, 237, 237, 0.66) !important;

                th {
                    vertical-align: middle;
                    border-bottom: 0;
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: rgba(51, 51, 51, 0.53);
                }
            }
        }

        tr {
            &:nth-child(odd) {
                background-color: #fff;
                height: 89px;
            }

            &:nth-child(even) {
                background-color: rgba(237, 237, 237, 0.66);
                height: 76px;
            }

            td {
                vertical-align: middle !important;
            }
        }
    }
}

div {
    &.error {
        color: $red;
        font-size: 11px;
        padding: 0 1px;
    }
}

*:focus {
    outline: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.border-dark {
    min-width: 40px;
    background: #bbb7b705;
    height: 35px;
    border-color: #343a40 !important;
}

modal-container {
    &.modal {
        div {
            &.modal-content {
                div {
                    &.modal-header {
                        egl-rectangle-icon {
                            position: relative;
                        }
                    }
                }
            }

            &.modal-full {
                div {
                    &.modal-content {
                        height: 98vh;
                        width: 98vw;
                        margin: auto auto;
                        text-align: center;
                    }
                }
            }

            &.modal-80 {
                max-width: 80% !important;
            }

            &.modal-fs {
                max-width: 100% !important;
                max-height: 100% !important;
            }

            &.egl-modal-footer {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 24px !important;
            }
        }

        .modal-dialog {
            max-width: 620px;
            min-width: fit-content;
        }

        .modal-content {
            border: 0;
        }

        .modal-body {
            .modal-main-title {
                font-weight: 800;
                text-transform: uppercase;
            }

            .modal-paragraph-title {
                padding-top: 20px;
                font-weight: 600;
            }
        }

        // &.fade {
        //     transition: opacity .02s ease-out;
        // }

        &.show .modal-dialog,
        &.fade .modal-dialog {
            transform: none;
            // transition: opacity .02s ease-out;
        }
    }
}

$spaces: (
    h20: 20px,
    h40: 40px,
    h60: 60px,
    h80: 89px,
    h100: 100px,
    h150: 150px,
);

@each $curSpace, $space in $spaces {
    .top-buffer-#{$curSpace} {
        margin-top: $space;
    }
}

::-webkit-input-placeholder {
    text-transform: initial;
}

.control-label {
    margin-right: 8px;

    &.static-text {
        margin: 0px;
        font-weight: normal !important;
    }
}

.submitted .ng-autocomplete.ng-invalid input {
    border: 1px solid red;
}

.popover {
    max-width: fit-content !important;
    border: solid 1px $color-medium-grey !important;
    border-radius: 2px !important;
    box-shadow: none;
}

.popover-body {
    padding: 30px 30px 15px !important;
}

.bs-popover-right > .arrow::before {
    border-right-color: $color-medium-grey !important;
}

.bs-popover-bottom > .arrow::before {
    border-bottom-color: $color-medium-grey !important;
}

/**
 * Datepicker custom styles
 */
.egl-datepicker__frame {
    width: 100%;

    bs-daterangepicker-inline-container {
        width: 100%;
    }
}

.egl-datepicker {
    box-shadow: none;
    max-width: rem-calc(660);
    width: 100%;
    margin: 0 auto;
    z-index: 2;

    > .bs-datepicker-container {
        padding: 0;
        width: 100%;
    }

    .bs-datepicker-head {
        padding: rem-calc(15);
        height: rem-calc(62);

        button {
            font-weight: 600;
            min-width: unset;
            color: $color-grey;
            text-transform: capitalize;

            &:hover {
                transition: none;
            }
        }

        button.previous,
        button.next {
            span {
                font-size: rem-calc(20);
            }

            &:disabled {
                background-color: transparent;
                color: rgba(0, 0, 0, 0.25);
                cursor: default;
            }
        }
    }

    // bs-days-calendar-view {
    //     width: rem-calc(300);
    //     border-radius: rem-calc(3);
    //     border: rem-calc(1) solid #979797;
    //     position: relative;
    // }

    .bs-datepicker-multiple {
        margin: 0 rem-calc(15) 0 0;
    }

    .bs-media-container {
        border-radius: rem-calc(3);
        border: rem-calc(1) solid $color-grey-97;
        position: relative;
        justify-content: space-between;
        align-items: center;
    }

    .bs-datepicker-body {
        min-height: auto;
        border: 0;
        padding-top: 0;

        table {
            table-layout: fixed;
            max-width: rem-calc(278);
            width: 100%;

            thead th {
                text-transform: uppercase;
                height: rem-calc(20);
                padding: 0 0 rem-calc(10);
                @include caption;
            }

            td {
                color: $color-grey;
                font-weight: 500;
            }

            &.days {
                td {
                    width: rem-calc(40);
                }

                span {
                    &.selected {
                        background-color: $color-dark-blue;
                        font-weight: 600;
                        border-radius: 50%;
                        color: $color-white;

                        &::before {
                            background: transparent;
                        }
                    }

                    &.in-range::before {
                        background: $color-dark-blue-07;
                    }

                    &.in-range {
                        color: $color-white;
                    }
                }
            }

            &.years {
                tr:not(:last-child) td span {
                    margin: 0;
                }
            }

            &.months,
            &.years {
                td {
                    &:hover,
                    &.is-highlighted,
                    &.is-highlighted:not(.disabled):not(.selected) span {
                        border-radius: rem-calc(3);
                    }

                    span,
                    &.is-highlighted span {
                        &.selected {
                            border-radius: rem-calc(3);
                            background-color: $color-dark-blue;
                            color: $color-white;
                        }
                    }
                }
            }

            &.days,
            &.months,
            &.years {
                td {
                    height: rem-calc(40);
                    line-height: rem-calc(40);
                    padding: 0;

                    &:hover,
                    &.is-highlighted,
                    &.is-highlighted:not(.disabled):not(.selected) span:not(.is-other-month) {
                        font-weight: 600;
                    }
                }

                span {
                    padding: 0;
                    height: 100%;
                    width: 100%;
                    margin: 0;
                    @include paragraph-xsmall;
                    line-height: rem-calc(40);

                    &::before {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

.egl-page {
    margin: rem-calc(80) auto;
}

// stile aggiunto per stilare l'innerHTML (contenuto) delle FAQ
.prod-faq__content > p {
    margin: 0;
}
