@import 'src/theme/base/functions.scss';
@import 'src/theme/base/typography.scss';
@import 'src/theme/base/variables.scss';
@import 'src/theme/base/colors.scss';

.egl-modal__wrapper {
  position: fixed;
  top: rem-calc(76);
  left: rem-calc(15);
  right: rem-calc(15);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $color-white;
  padding: rem-calc(30);
  border: 0;
  border-radius: rem-calc(2);
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 25%);
  $margin-tb: rem-calc(76);
  $margin-rl: rem-calc(15);
  max-height: calc(100vh - #{$margin-tb} - #{$margin-tb});
  width: calc(100% - #{$margin-rl} - #{$margin-rl});
  margin-left: auto;
  margin-right: auto;
}

.egl-modal__wrapper--big {
  @extend .egl-modal__wrapper;
  max-width: rem-calc(820);
}

.egl-modal__wrapper--small {
  @extend .egl-modal__wrapper;
  max-width: rem-calc(620);
}

.egl-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.egl-modal__header-icon {
  cursor: pointer;
}

.egl-modal__title {
  color: $color-grey;
  margin: 0;
  @include h3-bold;
}

.egl-modal__body {
  margin: rem-calc(30) 0;
}

.egl-modal__body--text-left {
  text-align: left;
}

.egl-modal__body--text-center {
  text-align: center;
}

.egl-modal__paragraph {
  color: $color-dark-grey;
  margin: 0;
  @include paragraph-small;
}

.egl-modal__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.egl-modal__footer--complex {
  @media screen and (min-width: $bp-tablet-small) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.egl-modal__footer--right-part {
  @media screen and (min-width: $bp-tablet-small) {
    display: flex;

    button {
      &:nth-child(2) {
        margin-left: rem-calc(30);
      }
    }
  }
}

.egl-modal__footer,
.egl-modal__footer--complex {

  button.cta-primary,
  button.cta-secondary {
    width: 100%;
    margin-bottom: $margin-bottom-small;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: $bp-tablet-small) {
      width: auto;
      margin-bottom: auto;

      &:last-child {
        margin-bottom: auto;
      }
    }

    @media screen and (max-width: $bp-tablet) {
      font-size: rem-calc(14);
    }
  }
}