// Styleguide color

// YELLOW
$color-yellow: #ffd300;
$color-light-yellow: #ffde40;
$color-dark-yellow: #f8ca00;
$color-yellow-hover-light: #fff6e3;
$color-yellow-hover-dark: #ffd77d;

// BEIGE
$color-light-beige: #ede8df;
$color-dark-beige: #d9d0c1;

// GREYSCALE
$color-white: #ffffff;
$color-extra-light-grey: #eaeaea;
$color-light-grey: #aaaaaa;
$color-lighter-grey: #f4f4f4;
$color-medium-grey: #767676;
$color-grey: #333333;
$color-dark-grey: #444444;
$color-black: #000000;
$color-grey-A6: #a6a6a6;
$color-grey-D8: #d8d8d8;
$color-grey-B8: #b8b8b8;
$color-soft-grey: #ced4da;
$color-old-medium-grey: #495057;
$color-grey-F0: #f0f0f0;
$color-grey-E1: #e1dfdd;
$color-grey-97: #979797;

// BLUE
$color-blue: #3b79b7;
$color-medium-blue: #83c2d6;
$color-dark-blue: #045999;
$color-heavenly-blue: #a8d0ff;
$color-light-blue: #cee7ef;
$color-dark-blue-hover: #004578;

// OTHER
$color-red: #d0021b;
$color-dark-red: #b60000;
$color-gas: #00669e;
$color-smarthome: #08b4b1;
$color-manutenzione: #5b5b5b;
$color-assicurazione: #917ba8;
$color-green: #21ad72;
$color-light-red: #fbe6e9;

$color-ds-blue: #2266e3;

// WITH OPACITY
$color-light-grey-05: rgba(170, 170, 170, 0.5);
$color-medium-grey-05: rgba(118, 118, 118, 0.5);
$color-grey-05: rgba(51, 51, 51, 0.5);
$color-grey-03: rgba(51, 51, 51, 0.3);
$color-grey-02: rgba(51, 51, 51, 0.2);
$color-white-05: rgba(255, 255, 255, 0.5);
$color-white-07: rgba(255, 255, 255, 0.7);
$color-white-08: rgba(255, 255, 255, 0.8);
$color-black-05: rgba(0, 0, 0, 0.5);
$color-light-yellow-05: rgba(255, 222, 64, 0.5);
$color-extra-light-grey-05: rgba(234, 234, 234, 0.5);
$color-dark-grey-045: rgba(68, 68, 68, 0.45);
$color-dark-blue-07: rgba(4, 89, 153, 0.7);
$color-dark-blue-03: rgba(4, 89, 153, 0.3);
$color-dark-blue-01: rgba(4, 89, 153, 0.1);
$color-dark-blue-005: rgba(4, 89, 153, 0.05);
$color-red-015: rgba(208, 2, 27, 0.15);
$color-red-opacity: rgba(208, 2, 27, 0.16);
$color-bg-active-blue: rgba(176, 216, 229, 0.5);
// RGB

$color-grey-216: rgb(216, 216, 216);