@mixin header-xlarge {
    font-size: rem-calc(74);
    font-weight: 400;
    line-height: line-height-calc(74px, 76px);
    letter-spacing: normal;
}

@mixin header-large {
    font-size: rem-calc(68);
    font-weight: 400;
    line-height: line-height-calc(68px, 76px);
    letter-spacing: normal;
}

@mixin header-medium {
    font-size: rem-calc(42);
    font-weight: 400;
    line-height: line-height-calc(42px, 52px);
    letter-spacing: normal;
}

@mixin header-small {
    font-size: rem-calc(32);
    font-weight: 400;
    line-height: line-height-calc(32px, 40px);
    letter-spacing: normal;
}

@mixin h1 {
    font-size: rem-calc(28);
    font-weight: 400;
    line-height: line-height-calc(28px, 36px);
    letter-spacing: normal;
}

@mixin h1-bold {
    @include h1;
    font-weight: 600;
}

@mixin h2 {
    font-size: rem-calc(24);
    font-weight: 400;
    line-height: line-height-calc(24px, 32px);
    letter-spacing: normal;
}

@mixin h2-bold {
    @include h2;
    font-weight: 600;
}

@mixin h3 {
    font-size: rem-calc(20);
    font-weight: 400;
    line-height: line-height-calc(20px, 28px);
    letter-spacing: normal;
}

@mixin h3-bold {
    @include h3;
    font-weight: 600;
}

@mixin h4 {
    font-size: rem-calc(18);
    font-weight: 400;
    line-height: line-height-calc(18px, 24px);
    letter-spacing: normal;
}

@mixin h4-bold {
    @include h4;
    font-weight: 600;
}

@mixin paragraph {
    font-size: rem-calc(16);
    font-weight: 400;
    line-height: line-height-calc(16px, 22px);
    letter-spacing: normal;
}

@mixin paragraph-bold {
    @include paragraph;
    font-weight: 600;
}

@mixin paragraph-small {
    font-size: rem-calc(14);
    font-weight: 400;
    line-height: line-height-calc(14px, 20px);
    letter-spacing: normal;
}

@mixin paragraph-small-bold {
    @include paragraph-small;
    font-weight: 600;
}

@mixin paragraph-xsmall {
    font-size: rem-calc(12);
    font-weight: 400;
    line-height: line-height-calc(12px, 16px);
    letter-spacing: normal;
}

@mixin paragraph-xsmall-bold {
    @include paragraph-xsmall;
    font-weight: 600;
}

@mixin caption {
    font-size: rem-calc(10);
    font-weight: 400;
    line-height: line-height-calc(10px, 14px);
    letter-spacing: normal;
}

@mixin caption-bold {
    @include caption;
    font-weight: 600;
}

.header-xlarge {
    @include header-xlarge;
}

.header-large {
    @include header-large;
}

.header-medium {
    @include header-medium;
}

.header-small {
    @include header-small;
}

.h1 {
    @include h1;
}

.h1-bold {
    @include h1-bold;
}

.h2 {
    @include h2;
}

.h2-bold {
    @include h2-bold;
}

.h3 {
    @include h3;
}

.h3-bold {
    @include h3-bold;
}

.h4 {
    @include h4;
}

.h4-bold {
    @include h4-bold;
}

.paragraph {
    @include paragraph;
}

.paragraph-bold {
    @include paragraph-bold;
}

.paragraph-small {
    @include paragraph-small;
}

.paragraph-small-bold {
    @include paragraph-small-bold;
}

.paragraph-xsmall {
    @include paragraph-xsmall;
}

.paragraph-xsmall-bold {
    @include paragraph-xsmall-bold;
}

.caption {
    @include caption;
}

.caption-bold {
    @include caption-bold;
}
