.header-xlarge {
  font-size: 4.625rem;
  font-weight: 400;
  line-height: 1.027027027;
  letter-spacing: normal;
}

.header-large {
  font-size: 4.25rem;
  font-weight: 400;
  line-height: 1.1176470588;
  letter-spacing: normal;
}

.header-medium {
  font-size: 2.625rem;
  font-weight: 400;
  line-height: 1.2380952381;
  letter-spacing: normal;
}

.header-small {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
}

.h1 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.2857142857;
  letter-spacing: normal;
}

.h1-bold {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.2857142857;
  letter-spacing: normal;
  font-weight: 600;
}

.h2 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.h2-bold {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
  font-weight: 600;
}

.h3 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
}

.h3-bold {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
  font-weight: 600;
}

.h4 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.h4-bold {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
  font-weight: 600;
}

.paragraph {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  letter-spacing: normal;
}

.paragraph-bold {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  letter-spacing: normal;
  font-weight: 600;
}

.paragraph-small {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
}

.paragraph-small-bold {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  font-weight: 600;
}

.paragraph-xsmall {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.paragraph-xsmall-bold {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
  font-weight: 600;
}

.caption {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
}

.caption-bold {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
  font-weight: 600;
}

.btn-primary, .btn-primary--blue, .btn-primary--white {
  padding: 0 1.875rem;
  background-color: #ffd300;
  color: #333333;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
  font-weight: 600;
  text-align: center;
  height: 3.125rem;
  border-radius: 0.1875rem;
  cursor: pointer;
  text-decoration: none;
  min-width: 8.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all linear;
}
.btn-primary:hover, .btn-primary--blue:hover, .btn-primary--white:hover, .btn-primary:active, .btn-primary--blue:active, .btn-primary--white:active, .btn-primary:focus, .btn-primary--blue:focus, .btn-primary--white:focus {
  background-color: #ffde40;
  outline: 0;
  transition: 0.2s all linear;
}
.btn-primary.disabled, .disabled.btn-primary--blue, .disabled.btn-primary--white {
  background-color: #eaeaea;
  color: #aaaaaa;
  cursor: not-allowed;
  pointer-events: none;
}

.btn-primary--white {
  background-color: #ffffff;
  border: 0.0625rem solid transparent;
}
.btn-primary--white:hover, .btn-primary--white:active, .btn-primary--white:focus {
  background-color: #ede8df;
  border: 0.0625rem solid transparent;
}

.btn-primary--blue {
  background-color: #336699;
  color: #ffffff;
}
.btn-primary--blue:hover, .btn-primary--blue:active, .btn-primary--blue:focus {
  background-color: #4e7ba9;
}

.btn-secondary {
  padding: 0 1.875rem;
  background-color: #ffffff;
  border: 0.0625rem solid #767676;
  color: #333333;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
  font-weight: 600;
  text-align: center;
  height: 3.125rem;
  min-width: 5.625rem;
  border-radius: 0.1875rem;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all linear;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
  border: 0.0625rem solid #ffd300;
  outline: 0;
  transition: 0.2s all linear;
}
.btn-secondary.disabled {
  border: 0.09375rem solid #cccccc;
  background-color: #eaeaea;
  color: #aaaaaa;
  cursor: not-allowed;
  pointer-events: none;
}

.btn--small, .btn--small-two-rows {
  padding: 0 1.25rem;
  height: 2rem;
  min-width: 5.625rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: 0.2s all linear;
}

.btn--small-two-rows {
  height: auto;
  max-width: 9.5625rem;
  color: #333333;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  font-weight: 600;
  transition: 0.2s all linear;
  padding: 0.5625rem 1.25rem;
}

.cta-primary {
  padding: 0 1.875rem;
  background-color: #045999;
  border: 0.0625rem solid #045999;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
  font-weight: 600;
  text-align: center;
  height: 2.5rem;
  border-radius: 0.1875rem;
  cursor: pointer;
  text-decoration: none;
  min-width: 8.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
}
.cta-primary:hover, .cta-primary:active, .cta-primary:focus {
  transition: all 0.2s linear;
  background-color: #004578;
  border: 0.0625rem solid #004578;
  outline: 0;
}
.cta-primary.disabled, .cta-primary:disabled {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
  color: #a6a6a6;
  cursor: not-allowed;
  pointer-events: none;
}
@media (min-width: 1720px) {
  .cta-primary {
    min-width: 12.5rem;
  }
}

.cta-secondary {
  padding: 0 1.875rem;
  background-color: #ffffff;
  border: 0.0625rem solid #a6a6a6;
  color: #333333;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
  font-weight: 600;
  text-align: center;
  height: 2.5rem;
  min-width: 8.75rem;
  border-radius: 0.1875rem;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
}
.cta-secondary:hover, .cta-secondary:active, .cta-secondary:focus {
  border: 0.0625rem solid #045999;
  outline: 0;
  transition: all 0.2s linear;
}
.cta-secondary.disabled, .cta-secondary:disabled {
  border: 0.09375rem solid #a6a6a6;
  background-color: #d8d8d8;
  color: #a6a6a6;
  cursor: not-allowed;
  pointer-events: none;
}
.cta-secondary.disabled:hover, .cta-secondary.disabled:active, .cta-secondary.disabled:focus, .cta-secondary:disabled:hover, .cta-secondary:disabled:active, .cta-secondary:disabled:focus {
  border: 0.09375rem solid #a6a6a6;
  background-color: #d8d8d8;
  color: #a6a6a6;
  cursor: not-allowed;
  pointer-events: none;
}
@media (min-width: 1720px) {
  .cta-secondary {
    min-width: 12.5rem;
  }
}

.link-cta {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  font-weight: 600;
  color: #333333;
  text-decoration: none;
  position: relative;
  text-align: center;
  padding: 0 0.09375rem;
  transition: all 0.2s linear;
  background-color: transparent;
  border: 0;
  height: auto;
  min-width: auto;
  text-transform: uppercase;
  outline: 0;
  cursor: pointer;
}
.link-cta::before {
  content: "";
  height: 0.5rem;
  width: 100%;
  background-color: #ffd300;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
}
.link-cta:hover, .link-cta:active, .link-cta:focus {
  outline: 0;
  color: #444444;
  transition: all 0.2s linear;
}
.link-cta:hover::before, .link-cta:active::before, .link-cta:focus::before {
  background-color: #ffde40;
}
.link-cta span {
  z-index: 1;
  margin: 0;
  position: relative;
}
.link-cta.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.link-cta--blue {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
  text-transform: uppercase;
  color: #2266e3;
  border: 0;
  background-color: transparent;
  padding: 0;
  position: relative;
  height: 1rem;
  min-width: unset;
}
.link-cta--blue::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.0625rem;
  background-color: #2266e3;
}
.link-cta--blue:hover::before {
  animation: move-underline 0.5s linear;
}

@keyframes move-underline {
  0% {
    width: 0%;
    transition: all 0.2s linear;
  }
  100% {
    width: 100%;
    transition: all 0.2s linear;
  }
}
.link {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  color: #045999;
  cursor: pointer;
  text-decoration: underline;
  margin: 0;
  background-color: transparent;
}

.link--bold {
  text-transform: uppercase;
  color: #045999;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  font-weight: 600;
}
.link--bold:hover {
  text-decoration: underline;
  color: #045999;
}

.egl-btn-arrow {
  position: relative;
  display: inline-block;
  align-items: center;
  min-width: unset;
  height: unset;
  background-color: transparent;
  color: #045999;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0;
  padding-right: 1.5625rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  font-weight: 600;
}
.egl-btn-arrow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/svg/single-light-right-blue.svg");
  background-repeat: no-repeat;
  background-size: 0.625rem;
  background-position: right;
}

.egl-btn-toggle-switch {
  --inactive-bg: #f0f0f0;
  --active-bg: #045999;
  --size: 1.875rem;
  appearance: none;
  width: calc(var(--size) * 1.8);
  height: var(--size);
  display: inline-block;
  border-radius: calc(var(--size) / 2);
  cursor: pointer;
  background-color: var(--inactive-bg);
  background-image: radial-gradient(circle calc(var(--size) / 2.2), #fff 100%, #0000 0), radial-gradient(circle calc(var(--size) / 1.5), #0003 0%, #0000 100%);
  background-repeat: no-repeat;
  background-position: calc(var(--size) / -2.6) 0;
  transition: background 0.2s ease-out;
}

.egl-btn-toggle-switch:checked {
  background-color: var(--active-bg);
  background-position: calc(var(--size) / 2.6) 0;
}

.header-xlarge {
  font-size: 4.625rem;
  font-weight: 400;
  line-height: 1.027027027;
  letter-spacing: normal;
}

.header-large {
  font-size: 4.25rem;
  font-weight: 400;
  line-height: 1.1176470588;
  letter-spacing: normal;
}

.header-medium {
  font-size: 2.625rem;
  font-weight: 400;
  line-height: 1.2380952381;
  letter-spacing: normal;
}

.header-small {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
}

.h1 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.2857142857;
  letter-spacing: normal;
}

.h1-bold {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.2857142857;
  letter-spacing: normal;
  font-weight: 600;
}

.h2 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.h2-bold {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
  font-weight: 600;
}

.h3 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
}

.h3-bold {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
  font-weight: 600;
}

.h4 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.h4-bold {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
  font-weight: 600;
}

.paragraph {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  letter-spacing: normal;
}

.paragraph-bold {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  letter-spacing: normal;
  font-weight: 600;
}

.paragraph-small {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
}

.paragraph-small-bold {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  font-weight: 600;
}

.paragraph-xsmall {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.paragraph-xsmall-bold {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
  font-weight: 600;
}

.caption {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
}

.caption-bold {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
  font-weight: 600;
}

.ds-input {
  min-width: 16.0625rem;
  background-color: #ffffff;
  border: 0.0625rem solid #a6a6a6;
  height: 2.1875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  font-weight: 600;
}

.egl-fieldset__radiobutton {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.egl-fieldset__paragraph--required::after {
  content: "*";
  color: #d0021b;
}

.egl-fieldset__radiobutton--y {
  margin-right: 1.875rem;
}

.egl-fieldset__paragraph, .egl-fieldset__paragraph--required {
  width: auto;
  margin: 0 1.875rem 0 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
}

.egl-fieldset__radiobutton-check, .egl-fieldset__radiobutton-check--disabled, .egl-fieldset__radiobutton-check:disabled {
  width: 1.5625rem;
  height: 1.5625rem;
  border: 1px solid #444444;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}
.egl-fieldset__radiobutton-check:checked::before, .egl-fieldset__radiobutton-check--disabled:checked::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #045999;
  border-color: #045999;
  border-radius: 50%;
}
.egl-fieldset__radiobutton-check:checked::after, .egl-fieldset__radiobutton-check--disabled:checked::after {
  content: "";
  position: absolute;
  top: 0.75rem;
  left: 0.375rem;
  background: #ffffff;
  width: 0.125rem;
  height: 0.125rem;
  box-shadow: 2px 0 0 #ffffff, 4px 0 0 #ffffff, 4px -2px 0 #ffffff, 4px -4px 0 #ffffff, 4px -6px 0 #ffffff, 4px -8px 0 #ffffff, 4px -10px 0 #ffffff;
  transform: rotate(45deg) scale(1.1);
}
.egl-fieldset__radiobutton-check--disabled, .egl-fieldset__radiobutton-check:disabled, .egl-fieldset__radiobutton-check--disabled:disabled {
  cursor: default;
  pointer-events: none;
}
.egl-fieldset__radiobutton-check--disabled:checked::before, .egl-fieldset__radiobutton-check:disabled:checked::before {
  background-color: #f0f0f0;
  border: 1px solid #aaaaaa;
}
.egl-fieldset__radiobutton-check--disabled:checked::after, .egl-fieldset__radiobutton-check:disabled:checked::after {
  background: #aaaaaa;
  box-shadow: 2px 0 0 #aaaaaa, 4px 0 0 #aaaaaa, 4px -2px 0 #aaaaaa, 4px -4px 0 #aaaaaa, 4px -6px 0 #aaaaaa, 4px -8px 0 #aaaaaa, 4px -10px 0 #aaaaaa;
}

.egl-fieldset__radiobutton-column-title-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1.875rem;
  max-width: 61.875rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.egl-fieldset__radiobutton-column-title--y {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.egl-fieldset__radiobutton-column-title--n {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.egl-fieldset__radiobutton-span {
  display: block;
  color: #767676;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.egl-fieldset__radiobutton-input-container {
  margin-top: 1.875rem;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1.875rem;
  max-width: 61.875rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.egl-fieldset__radiobutton-input {
  grid-column: span 2;
  text-align: center;
}

.egl-info-grey-text {
  color: #767676;
  position: relative;
  padding-left: 1.25rem;
  margin: 0.375rem 0 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}
.egl-info-grey-text::before {
  content: "";
  background-image: url("../../assets/svg/information_1.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 1rem;
  height: 1rem;
  filter: opacity(0.5);
}

.egl-danger-alert-msg, .egl-danger-alert-msg-bar {
  color: #d0021b;
  position: relative;
  padding-left: 1.3125rem;
  margin: 0.3125rem 0 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}
.egl-danger-alert-msg::before, .egl-danger-alert-msg-bar::before {
  content: "";
  background-image: url("../../assets/svg/important_3.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 1rem;
  height: 1rem;
  filter: invert(10%) sepia(83%) saturate(5753%) hue-rotate(347deg) brightness(95%) contrast(105%);
}

.egl-danger-alert-msg-bar {
  background-color: #fbe6e9;
  padding: 0.3125rem 0.3125rem 0.3125rem 1.625rem;
}
.egl-danger-alert-msg-bar::before {
  top: 50%;
  left: 0.3125rem;
  transform: translateY(-50%);
}

.egl-forms-two-col {
  width: 100%;
}
@media screen and (min-width: 49.375em) {
  .egl-forms-two-col {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    row-gap: 1.875rem;
    width: 100%;
  }
}
.egl-forms-two-col .egl-forms-two-col__item {
  display: block;
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 49.375em) {
  .egl-forms-two-col .egl-forms-two-col__item {
    flex: 0 0 calc(50% - 15px);
    margin-bottom: 0;
  }
}

.egl-forms-three-col__wrapper {
  width: 100%;
}
@media screen and (min-width: 49.375em) {
  .egl-forms-three-col__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.875rem 1.875rem;
    width: 100%;
  }
}
@media screen and (min-width: 61.875em) {
  .egl-forms-three-col__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
.egl-forms-three-col__wrapper .egl-forms-three-col__item, .egl-forms-three-col__wrapper .egl-forms-three-col__item--all {
  display: block;
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 49.375em) {
  .egl-forms-three-col__wrapper .egl-forms-three-col__item, .egl-forms-three-col__wrapper .egl-forms-three-col__item--all {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 49.375em) {
  .egl-forms-three-col__wrapper .egl-forms-three-col__item--all {
    grid-column: span 2;
  }
}
@media screen and (min-width: 61.875em) {
  .egl-forms-three-col__wrapper .egl-forms-three-col__item--all {
    grid-column: span 3;
  }
}

.header-xlarge {
  font-size: 4.625rem;
  font-weight: 400;
  line-height: 1.027027027;
  letter-spacing: normal;
}

.header-large {
  font-size: 4.25rem;
  font-weight: 400;
  line-height: 1.1176470588;
  letter-spacing: normal;
}

.header-medium {
  font-size: 2.625rem;
  font-weight: 400;
  line-height: 1.2380952381;
  letter-spacing: normal;
}

.header-small {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
}

.h1 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.2857142857;
  letter-spacing: normal;
}

.h1-bold {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.2857142857;
  letter-spacing: normal;
  font-weight: 600;
}

.h2 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.h2-bold {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
  font-weight: 600;
}

.h3 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
}

.h3-bold {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
  font-weight: 600;
}

.h4 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.h4-bold {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
  font-weight: 600;
}

.paragraph {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  letter-spacing: normal;
}

.paragraph-bold {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  letter-spacing: normal;
  font-weight: 600;
}

.paragraph-small {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
}

.paragraph-small-bold {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  font-weight: 600;
}

.paragraph-xsmall {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.paragraph-xsmall-bold {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
  font-weight: 600;
}

.caption {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
}

.caption-bold {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
  font-weight: 600;
}

.egl-modal__wrapper, .egl-modal__wrapper--small, .egl-modal__wrapper--big {
  position: fixed;
  top: 4.75rem;
  left: 0.9375rem;
  right: 0.9375rem;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  padding: 1.875rem;
  border: 0;
  border-radius: 0.125rem;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25);
  max-height: calc(100vh - 4.75rem - 4.75rem);
  width: calc(100% - 0.9375rem - 0.9375rem);
  margin-left: auto;
  margin-right: auto;
}

.egl-modal__wrapper--big {
  max-width: 51.25rem;
}

.egl-modal__wrapper--small {
  max-width: 38.75rem;
}

.egl-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.egl-modal__header-icon {
  cursor: pointer;
}

.egl-modal__title {
  color: #333333;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: normal;
  font-weight: 600;
}

.egl-modal__body {
  margin: 1.875rem 0;
}

.egl-modal__body--text-left {
  text-align: left;
}

.egl-modal__body--text-center {
  text-align: center;
}

.egl-modal__paragraph {
  color: #444444;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
}

.egl-modal__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 49.375em) {
  .egl-modal__footer--complex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 49.375em) {
  .egl-modal__footer--right-part {
    display: flex;
  }
  .egl-modal__footer--right-part button:nth-child(2) {
    margin-left: 1.875rem;
  }
}

.egl-modal__footer button.cta-primary,
.egl-modal__footer button.cta-secondary,
.egl-modal__footer--complex button.cta-primary,
.egl-modal__footer--complex button.cta-secondary {
  width: 100%;
  margin-bottom: 0.9375rem;
}
.egl-modal__footer button.cta-primary:last-child,
.egl-modal__footer button.cta-secondary:last-child,
.egl-modal__footer--complex button.cta-primary:last-child,
.egl-modal__footer--complex button.cta-secondary:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 49.375em) {
  .egl-modal__footer button.cta-primary,
.egl-modal__footer button.cta-secondary,
.egl-modal__footer--complex button.cta-primary,
.egl-modal__footer--complex button.cta-secondary {
    width: auto;
    margin-bottom: auto;
  }
  .egl-modal__footer button.cta-primary:last-child,
.egl-modal__footer button.cta-secondary:last-child,
.egl-modal__footer--complex button.cta-primary:last-child,
.egl-modal__footer--complex button.cta-secondary:last-child {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 61.875em) {
  .egl-modal__footer button.cta-primary,
.egl-modal__footer button.cta-secondary,
.egl-modal__footer--complex button.cta-primary,
.egl-modal__footer--complex button.cta-secondary {
    font-size: 0.875rem;
  }
}