@import 'src/theme/base/functions.scss';
@import 'src/theme/base/colors.scss';
@import 'src/theme/base/typography.scss';
@import 'src/theme/base/variables.scss';
@import 'src/theme/layout/structure.scss';

// stile per input as D365
.ds-input {
    min-width: rem-calc(257);
    background-color: $color-white;
    border: rem-calc(1) solid $color-grey-A6;
    height: rem-calc(35);
    @include paragraph-small-bold;
}

// Radiobutton

.egl-fieldset__radiobutton {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.egl-fieldset__paragraph--required {
    @extend .egl-fieldset__paragraph;

    &::after {
        content: '*';
        color: $color-red;
    }
}

.egl-fieldset__radiobutton--y {
    margin-right: rem-calc(30);
}

.egl-fieldset__paragraph {
    width: auto;
    margin: 0 rem-calc(30) 0 0;
    @include paragraph-small;
}

// Radiobutton Troubleshooting

.egl-fieldset__radiobutton-check {
    width: rem-calc(25);
    height: rem-calc(25);
    border: 1px solid $color-dark-grey;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    &:checked::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-dark-blue;
        border-color: $color-dark-blue;
        border-radius: 50%;
    }

    &:checked::after {
        content: '';
        position: absolute;
        top: rem-calc(12);
        left: rem-calc(6);
        background: $color-white;
        width: rem-calc(2);
        height: rem-calc(2);
        box-shadow: 2px 0 0 $color-white, 4px 0 0 $color-white, 4px -2px 0 $color-white, 4px -4px 0 $color-white, 4px -6px 0 $color-white, 4px -8px 0 $color-white, 4px -10px 0 $color-white;
        transform: rotate(45deg) scale(1.1);
    }

    &--disabled,
    &:disabled {
        cursor: default;
        pointer-events: none;
        @extend .egl-fieldset__radiobutton-check;

        &:checked::before {
            background-color: $color-grey-F0;
            border: 1px solid $color-light-grey;
        }

        &:checked::after {
            background: $color-light-grey;
            box-shadow: 2px 0 0 $color-light-grey, 4px 0 0 $color-light-grey, 4px -2px 0 $color-light-grey, 4px -4px 0 $color-light-grey, 4px -6px 0 $color-light-grey, 4px -8px 0 $color-light-grey, 4px -10px 0 $color-light-grey;
        }
    }
}

.egl-fieldset__radiobutton-column-title-container {
    @include grid;
}

.egl-fieldset__radiobutton-column-title--y {
    text-align: center;
    @include h4;
}

.egl-fieldset__radiobutton-column-title--n {
    text-align: center;
    @include h4;
}

.egl-fieldset__radiobutton-span {
    display: block;
    color: $color-medium-grey;
    @include paragraph-xsmall;
}

.egl-fieldset__radiobutton-input-container {
    margin-top: rem-calc(30);
    align-items: center;
    @include grid;
}

.egl-fieldset__radiobutton-input {
    grid-column: span 2;
    text-align: center;
}


// Info icon + grey-text

.egl-info-grey-text {
    color: $color-medium-grey;
    position: relative;
    padding-left: rem-calc(20);
    margin: rem-calc(6) 0 0;
    @include paragraph-xsmall;

    &::before {
        content: "";
        background-image: url('../../assets/svg/information_1.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: rem-calc(16);
        height: rem-calc(16);
        filter: opacity(0.5);
    }
}

// Error message

.egl-danger-alert-msg {
    color: $color-red;
    position: relative;
    padding-left: rem-calc(21);
    margin: rem-calc(5) 0 0;
    @include paragraph-xsmall;

    &::before {
        content: "";
        background-image: url('../../assets/svg/important_3.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: rem-calc(16);
        height: rem-calc(16);
        filter: invert(10%) sepia(83%) saturate(5753%) hue-rotate(347deg) brightness(95%) contrast(105%);
    }
}

// Errore message bar

.egl-danger-alert-msg-bar {
    background-color: $color-light-red;
    padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(26);
    @extend .egl-danger-alert-msg;

    &::before {
        top: 50%;
        left: rem-calc(5);
        transform: translateY(-50%);
    }
}


// Grid 2 columns

.egl-forms-two-col {
    width: 100%;

    @media screen and (min-width: $bp-tablet-small) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        row-gap: rem-calc(30);
        width: 100%;
    }

    .egl-forms-two-col__item {
        display: block;
        margin-bottom: rem-calc(30);

        @media screen and (min-width: $bp-tablet-small) {
            flex: 0 0 calc(50% - 15px);
            margin-bottom: 0;
        }
    }
}

// Grid 3 columns 

.egl-forms-three-col__wrapper {
    width: 100%;

    @media screen and (min-width: $bp-tablet-small) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: rem-calc(30) rem-calc(30);
        width: 100%;
    }

    @media screen and (min-width: $bp-tablet) {
        grid-template-columns: repeat(3, 1fr);
    }

    .egl-forms-three-col__item {
        display: block;
        margin-bottom: rem-calc(30);

        @media screen and (min-width: $bp-tablet-small) {
            margin-bottom: 0;
        }

        &--all {
            @extend .egl-forms-three-col__item;

            @media screen and (min-width: $bp-tablet-small) {
                grid-column: span 2;
            }

            @media screen and (min-width: $bp-tablet) {
                grid-column: span 3;
            }
        }
    }
}