// NOTE btn sono i bottoni gialli, CTA i bottoni blu

// BTN
// BTN Primario
.btn-primary {
    padding: 0 rem-calc(30);
    background-color: $color-yellow;
    color: $color-grey;
    @include h3-bold;
    text-align: center;
    height: rem-calc(50);
    border-radius: rem-calc(3);
    cursor: pointer;
    text-decoration: none;
    min-width: rem-calc(140);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s all linear;

    &:hover,
    &:active,
    &:focus {
        background-color: $color-light-yellow;
        outline: 0;
        transition: 0.2s all linear;
    }

    &.disabled {
        background-color: $color-extra-light-grey;
        color: $color-light-grey;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.btn-primary--white {
    @extend .btn-primary;
    background-color: $color-white;
    border: rem-calc(1) solid transparent;

    &:hover,
    &:active,
    &:focus {
        background-color: $color-light-beige;
        border: rem-calc(1) solid transparent;
    }
}

.btn-primary--blue {
    @extend .btn-primary;
    background-color: #336699;
    color: $color-white;

    &:hover,
    &:active,
    &:focus {
        background-color: #4e7ba9;
    }
}

// BTN Secondario
.btn-secondary {
    padding: 0 rem-calc(30);
    background-color: $color-white;
    border: rem-calc(1) solid $color-medium-grey;
    color: $color-grey;
    @include h3-bold;
    text-align: center;
    height: rem-calc(50);
    min-width: rem-calc(90);
    border-radius: rem-calc(3);
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s all linear;

    &:hover,
    &:active,
    &:focus {
        border: rem-calc(1) solid $color-yellow;
        outline: 0;
        transition: 0.2s all linear;
    }

    &.disabled {
        border: rem-calc(1.5) solid #cccccc;
        background-color: $color-extra-light-grey;
        color: $color-light-grey;
        cursor: not-allowed;
        pointer-events: none;
    }
}

// BTN Small
.btn--small {
    padding: 0 rem-calc(20);
    height: rem-calc(32);
    min-width: rem-calc(90);
    @include paragraph-small-bold;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: 0.2s all linear;
}

.btn--small-two-rows {
    @extend .btn--small;
    // height: rem-calc(50);
    height: auto;
    max-width: rem-calc(153);
    color: $color-grey;
    @include paragraph-small-bold;
    transition: 0.2s all linear;
    padding: rem-calc(9) rem-calc(20);
}

// sostituisco nome btn con cta, altrimenti problemi con bootstrap
// CTA Primaria
.cta-primary {
    padding: 0 rem-calc(30);
    background-color: $color-dark-blue;
    border: rem-calc(1) solid $color-dark-blue;
    color: $color-white;
    @include h3-bold;
    text-align: center;
    height: rem-calc(40);
    border-radius: rem-calc(3);
    cursor: pointer;
    text-decoration: none;
    min-width: rem-calc(140);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s linear;

    &:hover,
    &:active,
    &:focus {
        transition: all 0.2s linear;
        background-color: #004578;
        border: rem-calc(1) solid #004578;
        outline: 0;
    }

    &.disabled,
    &:disabled {
        background-color: $color-grey-D8;
        border-color: $color-grey-D8;
        color: $color-grey-A6;
        cursor: not-allowed;
        pointer-events: none;
    }

    @media (min-width: 1720px) {
        min-width: rem-calc(200);
    }
}

// CTA Secondaria
.cta-secondary {
    padding: 0 rem-calc(30);
    background-color: $color-white;
    border: rem-calc(1) solid $color-grey-A6;
    color: $color-grey;
    @include h3-bold;
    text-align: center;
    height: rem-calc(40);
    min-width: rem-calc(140);
    border-radius: rem-calc(3);
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s linear;

    &:hover,
    &:active,
    &:focus {
        border: rem-calc(1) solid $color-dark-blue;
        outline: 0;
        transition: all 0.2s linear;
    }

    &.disabled,
    &:disabled {
        border: rem-calc(1.5) solid $color-grey-A6;
        background-color: $color-grey-D8;
        color: $color-grey-A6;
        cursor: not-allowed;
        pointer-events: none;

        &:hover,
        &:active,
        &:focus {
            border: rem-calc(1.5) solid $color-grey-A6;
            background-color: $color-grey-D8;
            color: $color-grey-A6;
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    @media (min-width: 1720px) {
        min-width: rem-calc(200);
    }
}

// CTA Link
.link-cta {
    @include paragraph-small-bold;
    color: $color-grey;
    text-decoration: none;
    position: relative;
    text-align: center;
    padding: 0 rem-calc(1.5);
    transition: all 0.2s linear;
    background-color: transparent;
    border: 0;
    height: auto;
    min-width: auto;
    text-transform: uppercase;
    outline: 0;
    cursor: pointer;

    &::before {
        content: '';
        height: rem-calc(8);
        width: 100%;
        background-color: $color-yellow;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 0;
    }

    &:hover,
    &:active,
    &:focus {
        outline: 0;
        color: $color-dark-grey;
        transition: all 0.2s linear;

        &::before {
            background-color: $color-light-yellow;
        }
    }

    span {
        z-index: 1;
        margin: 0;
        position: relative;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
}

//CTA BLUE

.link-cta--blue {
    @include paragraph-xsmall;
    text-transform: uppercase;
    color: $color-ds-blue;
    border: 0;
    background-color: transparent;
    padding: 0;
    position: relative;
    height: rem-calc(16);
    min-width: unset;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: rem-calc(1);
        background-color: $color-ds-blue;
    }

    &:hover {
        &::before {
            animation: move-underline 0.5s linear;
        }
    }
}

@keyframes move-underline {
    0% {
        width: 0%;
        transition: all 0.2s linear;
    }

    100% {
        width: 100%;
        transition: all 0.2s linear;
    }
}

// nuovi Link
.link {
    @include paragraph-small;
    color: $color-dark-blue;
    cursor: pointer;
    text-decoration: underline;
    margin: 0;
    background-color: transparent;
}

.link--bold {
    text-transform: uppercase;
    color: $color-dark-blue;
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 0;
    @include paragraph-small-bold;

    &:hover {
        text-decoration: underline;
        color: $color-dark-blue;
    }
}

// Button + arrow

.egl-btn-arrow {
    position: relative;
    display: inline-block;
    align-items: center;
    min-width: unset;
    height: unset;
    background-color: transparent;
    color: $color-dark-blue;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0;
    padding-right: rem-calc(25);
    @include paragraph-small-bold;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../../assets/svg/single-light-right-blue.svg');
        background-repeat: no-repeat;
        background-size: rem-calc(10);
        background-position: right;
    }
}

// Toggle Switch

.egl-btn-toggle-switch {
    --inactive-bg: #f0f0f0;
    --active-bg: #045999;
    --size: 1.875rem;
    appearance: none;
    width: calc(var(--size) * 1.8);
    height: var(--size);
    display: inline-block;
    border-radius: calc(var(--size) / 2);
    cursor: pointer;
    background-color: var(--inactive-bg);
    background-image: radial-gradient(circle calc(var(--size) / 2.2),
            #fff 100%,
            #0000 0),
        radial-gradient(circle calc(var(--size) / 1.5), #0003 0%, #0000 100%);
    background-repeat: no-repeat;
    background-position: calc(var(--size) / -2.6) 0;
    transition: background 0.2s ease-out;
}

.egl-btn-toggle-switch:checked {
    background-color: var(--active-bg);
    background-position: calc(var(--size) / 2.6) 0;
}